import { Routes, Route } from "react-router-dom";
import {useEffect} from "react";
import Login from "./screens/login/login";
import Dashboard from "./screens/dashboard/dashboard";
import KPDUDetail from "./screens/detail/detail";
import Kpdus from "./screens/kpdus/kpdus";

function LoginRedirect() {
    useEffect(() => {
        window.location.replace('/login');
    }, []);
    return (<div></div>);
}

export default function RouteContainer() {
    let authenticated = localStorage.getItem('access_token') !== null;

    return (
        <Routes>
            {authenticated &&
                <>
                    <Route path={'/list'} element={<Kpdus/>}/>
                    <Route path={'/'} element={<Dashboard/>}/>
                    <Route path={'/detail/:kpdu_id/'} element={<KPDUDetail/>}/>
                </>
            }
            <Route path={'/login'} element={<Login/>}/>
            <Route path="*" element={<LoginRedirect/>}/>
        </Routes>
    );
}