import { useState } from "react";

import styles from "./index.module.css";

export function InactivitySection({ data, form, setForm, setSaveIsDisabled }) {
  const [error, setError] = useState("");

  const handleCheckboxChange = (event) => {
    setForm({
      ...form,
      check_inactive_ports: event.target.checked,
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (value === "") {
      setError("Debe colocar los segundos.");
      setSaveIsDisabled(true);
      setForm({
        ...form,
        elapsed_time_to_check_inactive_ports: undefined,
      });
    } else if (value >= 0) {
      setError("");
      setSaveIsDisabled(false);
      setForm({
        ...form,
        elapsed_time_to_check_inactive_ports: parseInt(event.target.value),
      });
    } else {
      setError("Los segundos no pueden tomar valores negativos.");
      setSaveIsDisabled(true);
    }
  };

  return (
    <div className="mt-4 mx-5 d-flex justify-center align-items-center flex-column">
      <div
        className={`d-flex justify-center align-items-center ${styles["custom-container"]}`}
      >
        <div className="label text-black">Activar</div>
        <input
          type="checkbox"
          checked={form.check_inactive_ports}
          onChange={handleCheckboxChange}
        />
      </div>
      <div
        className={`d-flex justify-center align-items-center ${styles["custom-container"]}`}
      >
        <div className="label text-black">Segundos</div>
        <input
          type="number"
          value={form.elapsed_time_to_check_inactive_ports}
          onChange={handleInputChange}
          className={`${styles["small-custom-name-input"]}`}
        />
      </div>
      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
}

