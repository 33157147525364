import React, { useEffect, useState } from "react";

import styles from "../../detail.module.css";
import LineGraph from "../line_graph/line_graph";
import axiosInstance from "../../../../AxiosInstance";

export default function OutputGraphicGroup({ selectedOutput, kpdu_id }) {
  const [data, setData] = useState([]);
  const [dataHour, setDataHour] = useState([]);
  const [graphNames, setGraphNames] = useState({});
  const [locked, setLocked] = useState(false);
  const [filter, setFilter] = useState("");
  const [hasSeconds, setHasSeconds] = useState(false);
  const [hasTime, setHasTime] = useState(true);
  const [loading, setLoading] = useState(false);

  const getResponse = async () => {
    console.log("filter", filter);
    const url = `kpdu/${kpdu_id}/output_data/?filter=${filter}`;

    try {
      const response = await axiosInstance.get(url, {});

      setLocked(false);
      let data = response.data.map((item) => {
        item.avg_power = (item.avg_power / 1000).toFixed(1);
        item.avg_voltage = (item.avg_voltage / 1000).toFixed(1);
        item.avg_current = (item.avg_current / 1000).toFixed(1);
        return item;
      });
      return data;
    } catch (error) {
      return [];
    }
  };

  const getData = async () => {
    if (locked) return;
    setLocked(true);

    const response = await getResponse();
    setData(response);
  };

  const getDataHour = () => {
    axiosInstance
      .get(`kpdu/${kpdu_id}/output_data_hour/?number=${selectedOutput}`)
      .then((response) => {
        if (response?.status !== 200) {
          return;
        }
        setDataHour(response.data);
      });
  };

  useEffect(() => {
    getDataHour();
  }, [selectedOutput]);

  useEffect(() => {
    if (selectedOutput === -1) {
      setGraphNames({
        voltage: "input_voltage",
        current: "input_current",
        power: "input_power",
      });
    } else {
      setGraphNames({
        voltage: `voltages[${selectedOutput - 1}]`,
        current: `currents[${selectedOutput - 1}]`,
        power: `powers[${selectedOutput - 1}]`,
      });
    }

    getData();

    const interval = setInterval(() => {
      const now = new Date();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();

      if (filter === "") {
        setHasSeconds(true);
        setHasTime(true);
      } else if (filter === "hour") {
        setHasSeconds(false);
        setHasTime(true);
      } else if (filter === "day") {
        setHasSeconds(false);
        setHasTime(true);
      } else if (filter === "week") {
        setHasSeconds(false);
        setHasTime(false);
      } else if (filter === "month") {
        setHasSeconds(false);
        setHasTime(false);
      }

      if (currentMinute === 0 && currentSecond === 0) {
        getDataHour();
      }
      getData();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedOutput, locked, filter]);

  // const anio = new Date().getFullYear();
  //
  // const mes = () => {
  //   const nombreMes = new Intl.DateTimeFormat("es-ES", {
  //     month: "long",
  //   }).format(new Date());
  //   return nombreMes.charAt(0).toUpperCase() + nombreMes.slice(1);
  // };

  const onFilterClick = async (_filter) => {
    // setLoading(true);
    setFilter(_filter);
    // try {
    //   const response = await getResponse();
    //   setData(response);
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const filters = () => (
    <div className={"d-flex"}>
      <div
        className={`${styles.frequency} ${filter === "" ? styles.selected : ""}`}
        onClick={() => onFilterClick("")}
        style={loading && filter === "" ? { pointerEvents: "none" } : {}}
      >
        Últimos 60
        {filter === "" && loading && <div className={styles.spinner}></div>}
      </div>
      <div
        className={`${styles.frequency} ${filter === "hour" ? styles.selected : ""}`}
        onClick={() => onFilterClick("hour")}
        style={loading && filter === "hour" ? { pointerEvents: "none" } : {}}
      >
        Hora
        {filter === "hour" && loading && <div className={styles.spinner}></div>}
      </div>
      <div
        className={`${styles.frequency} ${filter === "day" ? styles.selected : ""}`}
        onClick={() => onFilterClick("day")}
        style={loading && filter === "day" ? { pointerEvents: "none" } : {}}
      >
        Día
        {filter === "day" && loading && <div className={styles.spinner}></div>}
      </div>
      <div
        className={`${styles.frequency} ${filter === "week" ? styles.selected : ""}`}
        onClick={() => onFilterClick("week")}
        style={loading && filter === "week" ? { pointerEvents: "none" } : {}}
      >
        Semana
        {filter === "week" && loading && <div className={styles.spinner}></div>}
      </div>
      {/* <div */}
      {/*   className={`${styles.frequency} ${filter === "month" ? styles.selected : ""}`} */}
      {/*   onClick={() => onFilterClick("month")} */}
      {/*   style={loading && filter === "month" ? { pointerEvents: "none" } : {}} */}
      {/* > */}
      {/*   Mes */}
      {/*   {filter === "month" && loading && ( */}
      {/*     <div className={styles.spinner}></div> */}
      {/*   )} */}
      {/* </div> */}
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        borderRadius: "15px",
        padding: "12px",
        boxShadow:
          "rgba(0, 0, 0, 8%) 0 8px 13px -2px, rgba(27, 41, 74, 0.29) 0 17px 4px -14px",
      }}
    >
      {/* GENERAL */}
      <LineGraph
        unit={"Wh"}
        min={0}
        max={100}
        color={"#8B41C5"}
        secondaryColor={"#8B41C5"}
        entries={dataHour}
        valueName={"wh"}
        keyProperty="hour_start"
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/general.png"}
                width={"19px"}
                height={"24px"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Consumo Electrico</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            {/* <div className={"d-flex"}> */}
            {/*   <div className={styles.frequency}> */}
            {/*     Año <div>{anio}</div> */}
            {/*   </div> */}
            {/*   <div className={styles.frequency}> */}
            {/*     Mes <div>{mes()}</div> */}
            {/*   </div> */}
            {/*   <div className={styles.frequency}>Semana</div> */}
            {/* </div> */}
          </div>,
        ]}
      />
      <div className={"pb-3"} />
      {/* VOLTAJE */}
      <LineGraph
        unit={"V"}
        min={0}
        max={24}
        entries={data}
        color={"#E71B29"}
        valueName={graphNames?.voltage}
        keyProperty="created_at"
        secondaryColor={"#E71B29"}
        withSeconds={hasSeconds}
        withTime={hasTime}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/voltage.png"}
                width={"16px"}
                height={"100%"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Voltaje</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            {filters()}
          </div>,
        ]}
      />
      <div className={"pb-3"} />
      {/* CORRIENTE */}
      <LineGraph
        unit={"A"}
        min={0}
        max={20}
        entries={data}
        color={"#D08E2A"}
        valueName={graphNames?.current}
        keyProperty="created_at"
        secondaryColor={"#D08E2A"}
        withSeconds={hasSeconds}
        withTime={hasTime}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/current.png"}
                width={"15px"}
                height={"22px"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Corriente</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            {filters()}
          </div>,
        ]}
      />
      <div className={"pb-3"} />
      {/* CONSUMO */}
      <LineGraph
        unit={"W"}
        min={0}
        max={480}
        entries={data}
        color={"#375395"}
        valueName={graphNames?.power}
        keyProperty="created_at"
        secondaryColor={"#375395"}
        withSeconds={hasSeconds}
        withTime={hasTime}
        header={[
          <div
            className={
              "d-flex justify-content-between align-items-center px-3 py-3"
            }
          >
            <div className={"d-flex align-items-center"}>
              <img
                alt={"thunder"}
                src={"/icons/graphs/power.png"}
                width={"13px"}
                height={"100%"}
                className={"me-2"}
              />
              <div
                className={"d-flex align-items-center"}
                style={{
                  color: "#19294999",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                <div className={"pe-2"}>Potencia</div>
                {selectedOutput !== -1 ? (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    OUT {selectedOutput}
                  </div>
                ) : (
                  <div
                    style={{
                      color: "#F32735",
                    }}
                  >
                    IN
                  </div>
                )}
              </div>
            </div>
            {filters()}
          </div>,
        ]}
      />
    </div>
  );
}
