import React, { useEffect, useState } from "react";
import styles from "./switch.module.css";

export default function Switch({ value, onChange, id }) {
  const [kpduReading, setKpduReading] = React.useState(value);
  const [localValue, setLocalValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [firstUpdate, setFirstUpdate] = useState(true);

  useEffect(() => {
    if (firstUpdate) {
      setFirstUpdate(false);
      return;
    }
    if (localValue === null) setLocalValue(value);
    setKpduReading(value);
  }, [value]);

  useEffect(() => {
    if (kpduReading === localValue) setLoading(false);
    else if (kpduReading !== localValue && !loading) setLocalValue(kpduReading);
  }, [kpduReading]);

  const handleClick = (event) => {
    if (loading) return;
    setLoading(true);
    event.stopPropagation();
    const oldActive = localValue;
    setLocalValue((prev) => !prev);
    if (onChange) onChange(!oldActive);
  };

  return (
    <div className={styles.container}>
      <div className={"d-flex justify-content-center"}>
        <div
          className={`${styles.switch} ${!localValue ? styles.inactive : ""} ${
            loading ? styles.loading : ""
          }`}
          onClick={handleClick}
        >
          <div className={styles.toggle}>
            {loading && <div className={styles.spinner} />}
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            color: localValue ? "#429C23" : "#C7C7C7",
            fontWeight: 600,
          }}
        >
          {localValue ? "ON" : "OFF"}
        </div>
      </div>
    </div>
  );
}
