import React, { useEffect, useState } from "react";
import styles from "./kpdus.module.css";
import Sidebar from "../../components/sidebar/sidebar";
import { useNavigate } from "react-router-dom";
import ViewSelector from "./components/view_selector/view_selector";
import Table from "../../components/ajonjolib/table/table";
import StatusPill from "../../components/status_pill/status_pill";
import axiosInstance from "../../AxiosInstance";
import { openModal } from "../../components/ajonjolib/toasts/toast/toast";
import OutputStatesModal from "../detail/modals/output_states";
import { formatTimestamp, kpduIsReady } from "../../utils/utils";
import { FiEdit } from "react-icons/fi";
import EditKpduModal from "../detail/modals/edit_kpdu_name";
import StatusDot from "../../components/status_dot";
import Loader from "../../components/loader/loader";

function KpduBox({ selected, data, setSelectedId }) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  const details = () => {
    navigate(`/detail/${data.id}/`);
  };

  useEffect(() => {
    // const updated_at = data?.updated_at;
    // // if 3 minutes since last update then inactive
    // let active = false;
    // let now = new Date();
    // let lastActivity = new Date(updated_at);
    // let diff = (now - lastActivity) / 1000;
    // if (diff < 60) {
    //   active = true;
    // }

    const lastHeartbeat = data?.last_heartbeat;
    if (lastHeartbeat) {
      setIsActive(kpduIsReady(lastHeartbeat));
    }
  }, [data]);

  const onEdit = async (event) => {
    event.stopPropagation();
    setSelectedId(data.id);
  };

  return (
    <div>
      <div
        className={`${styles.kpduContainer} ${selected ? styles.selected : ""}`}
        onClick={() => details()}
      >
        <div className={styles.edit} onClick={onEdit}>
          <FiEdit size={18} />
        </div>
        <div className={styles.kpduName}>{data?.name}</div>
        <div className={styles.divider} />
        <div className={styles.kpduDetails}>
          <div className={"d-flex justify-content-between"}>
            <div style={{ fontWeight: "bold" }}>U. Con</div>
            <div style={{ fontWeight: "lighter" }}>
              {formatTimestamp(data.updated_at)}
            </div>
          </div>
          <div className={"d-flex align-items-center gap-1"}>
            <div style={{ fontWeight: "bold" }}>Estado</div>
            <StatusDot isReady={isActive} />
          </div>
        </div>
      </div>
    </div>
  );
}

function KpduItemView({ data, setSelectedId }) {
  return (
    <div className="d-flex flex-wrap">
      {data?.map((entry) => {
        return (
          <KpduBox key={entry.id} data={entry} setSelectedId={setSelectedId} />
        );
      })}
    </div>
  );
}

function KpduListView({ data }) {
  const cols = [
    {
      title: "Nombre",
      key: "name",
      code: (row, data) => {
        return <div style={{ color: "#F32735", fontWeight: 600 }}>{data}</div>;
      },
    },
    { title: "Modelo de KPDU", key: "model.name" },
    { title: "Serial de Vehículo", key: "reference" },
    { title: "Serial de KPDU", key: "ipv4" },
    {
      title: "Última Conexión",
      key: "updated_at",
      code: (row, data) => {
        return formatTimestamp(data);
      },
    },
    {
      title: "Estado",
      key: "updated_at",
      code: (row, data) => {
        // if 3 minutes since last update then inactive
        let active = false;
        let now = new Date();
        let lastActivity = new Date(data);
        let diff = (now - lastActivity) / 1000;
        if (diff < 60) {
          active = true;
        }
        return <StatusPill value={active} />;
      },
    },
  ];

  return <Table model={"kpdu"} cols={cols} />;
}

export default function Kpdus() {
  const [viewType, setViewType] = useState("items");
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance.get("kpdu/").then((response) => {
      if (response?.status !== 200) {
        return;
      }
      setData(response.data);
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selectedId) {
      const kpduName = data.filter((kpdu) => kpdu.id === selectedId)[0].name;
      openModal(`edit_kpdu_${selectedId}`, { selectedId, kpduName });
    }
  }, [selectedId]);

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <EditKpduModal id={selectedId} setSelectedId={setSelectedId} />
      <Sidebar selectedIndex={1} />
      <ViewSelector setViewType={setViewType} viewType={viewType} />
      <OutputStatesModal />

      <div
        className={"d-flex"}
        style={{
          marginLeft: "300px",
          marginRight: "20px",
          paddingTop: "80px",
        }}
      >
        {viewType === "items" ? (
          <KpduItemView data={data} setSelectedId={setSelectedId} />
        ) : (
          <KpduListView data={data} />
        )}
      </div>
    </div>
  );
}
