import React, { useState } from "react";
import styles from "./text.module.css";

export default function Text({
  value,
  onChange,
  className,
  style,
  disabled,
  placeholder,
}) {
  const [focused, setFocused] = useState(false);

  return (
    <div className={className} style={style}>
      <div className={styles.container}>
        <input
          type="text"
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
          className={styles.input}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

