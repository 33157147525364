import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { parsePortAssignment } from "../../../../utils/utils";

import styles from "./index.module.css";

export function ResetSection({ data, form, setForm, setSaveIsDisabled }) {
  const [duplicatePorts, setDuplicatePorts] = useState([]);

  const findDuplicates = (arr) => {
    const seen = new Set();
    const duplicates = new Set();

    arr.forEach((item, index) => {
      if (seen.has(item)) {
        duplicates.add(item);
      } else {
        seen.add(item);
      }
    });

    return [...duplicates];
  };

  useEffect(() => {
    const duplicates = findDuplicates(form["ports_reset_order"]);
    const isDisabled = duplicates.length > 0;
    setDuplicatePorts(duplicates);
    console.log("duplicatePorts", duplicates);
    console.log("duplicatePorts condition", duplicates.length > 0);
    console.log("isDisabled", isDisabled);
    setSaveIsDisabled(isDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const clockSvg = (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#284480"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const handleTimeInputChange = (fieldName, idx, value) => {
    if (value === "") {
      setForm({
        ...form,
        [fieldName]: form[fieldName].map((val, index) =>
          index === idx ? "" : val,
        ),
      });
    } else {
      // Validar que el valor no contenga decimales
      if (/^\d+$/.test(value)) {
        const numValue = Math.max(0, Number(value));
        setForm({
          ...form,
          [fieldName]: form[fieldName].map((val, index) =>
            index === idx ? numValue : val,
          ),
        });
      }
    }
  };

  const handlePortOrderChange = (fieldName, idx, value) => {
    if (value === "") {
      setForm({
        ...form,
        [fieldName]: form[fieldName].map((val, index) =>
          index === idx ? "" : val,
        ),
      });
    } else {
      // Validar que el valor no contenga decimales
      if (/^\d+$/.test(value)) {
        const numValue = Math.max(1, Math.min(Number(value), data.ids.length));
        setForm({
          ...form,
          [fieldName]: form[fieldName].map((val, index) =>
            index === idx ? numValue : val,
          ),
        });
      }
    }
  };

  const handleBlur = (fieldName, idx, baseValue = 0) => {
    if (form[fieldName][idx] === "") {
      setForm({
        ...form,
        [fieldName]: form[fieldName].map((val, index) =>
          index === idx ? baseValue : val,
        ),
      });
    }
  };

  const portIsDuplicate = (idx) => {
    return duplicatePorts.includes(form["ports_reset_order"][idx]);
  };

  return (
    <div className={"mt-4"}>
      <p>Indica el orden de reset de puertos.</p>
      <Table>
        <thead>
          <tr>
            <th>Puerto</th>
            <th>Orden</th>
            <th>Tiempo entre puertos (s)</th>
            <th>Reset time (s)</th>
          </tr>
        </thead>
        <tbody>
          {data?.ids.map((port, idx) => {
            return (
              <tr key={port} className="align-middle">
                <td className={styles.portLabel}>
                  {parsePortAssignment(data.port_assignment, port)}
                </td>
                <td>
                  <input
                    className={`${styles["custom-input"]} ${
                      portIsDuplicate(idx) ? styles["error-input"] : ""
                    }`}
                    type="number"
                    step="1"
                    value={form["ports_reset_order"][idx]}
                    onChange={(e) =>
                      handlePortOrderChange(
                        "ports_reset_order",
                        idx,
                        e.target.value,
                      )
                    }
                    onBlur={() => handleBlur("ports_reset_order", idx, idx + 1)}
                  />
                </td>
                <td>
                  <div className={styles["input-container"]}>
                    {clockSvg}
                    <input
                      className={styles["custom-input"]}
                      type="number"
                      value={form["delay_between_devices"][idx]}
                      onChange={(event) =>
                        handleTimeInputChange(
                          "delay_between_devices",
                          idx,
                          event.target.value,
                        )
                      }
                      onBlur={() => handleBlur("delay_between_devices", idx)}
                    />
                  </div>
                </td>
                <td>
                  <div className={styles["input-container"]}>
                    {clockSvg}
                    <input
                      className={styles["custom-input"]}
                      type="number"
                      value={form["ports_reset_time"][idx]}
                      onChange={(event) =>
                        handleTimeInputChange(
                          "ports_reset_time",
                          idx,
                          event.target.value,
                        )
                      }
                      onBlur={() => handleBlur("ports_reset_time", idx)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {/* <div className={styles["fso-container"]}> */}
      {/*   <input */}
      {/*     type="checkbox" */}
      {/*     checked={form["friendly_switch_on"]} */}
      {/*     onChange={(event) => { */}
      {/*       setForm({ */}
      {/*         ...form, */}
      {/*         friendly_switch_on: event.target.checked, */}
      {/*       }); */}
      {/*     }} */}
      {/*   /> */}
      {/*   Friendly switch on */}
      {/* </div> */}
      {duplicatePorts.length > 0 && (
        <div className={styles["error-message"]}>Hay puertos repetidos</div>
      )}
    </div>
  );
}
