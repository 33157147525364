import React, { useState, useEffect } from "react";
import styles from "./radioButtons.module.css"; // Cambia el archivo CSS según tus necesidades

export default function HorizontalRadioButtons({ value, onChange, id }) {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleRadioChange = (newValue) => {
    setSelectedValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div className={styles.container}>
      <label className={styles.radioLabel}>
        <input
          type="radio"
          value={1}
          checked={selectedValue === 1}
          onChange={() => handleRadioChange(1)}
        />
        Default state
      </label>
      <label className={styles.radioLabel}>
        <input
          type="radio"
          value={2}
          checked={selectedValue === 2}
          onChange={() => handleRadioChange(2)}
        />
        Last state
      </label>
    </div>
  );
}
