// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loaderOverlay__fvglZ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.6
  );
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_loaderSpinner__vGDLk {
  color: #f32735;
  width: 4rem;
  height: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ;;;;;GAKC;EACD,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd","sourcesContent":[".loaderOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(\n    255,\n    255,\n    255,\n    0.6\n  );\n  z-index: 1050;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.loaderSpinner {\n  color: #f32735;\n  width: 4rem;\n  height: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderOverlay": `loader_loaderOverlay__fvglZ`,
	"loaderSpinner": `loader_loaderSpinner__vGDLk`
};
export default ___CSS_LOADER_EXPORT___;
