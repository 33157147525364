// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpdus_container__YfuhG {
}

.kpdus_kpduContainer__eF6mg {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  min-height: 200px;
  min-width: 174px;
  max-height: 200px;
  max-width: 174px;
  border-radius: 17px;
  color: #e72d39;
  background-color: #ffffff;
  box-shadow: 0 8px 13px -2px rgba(0, 0, 0, 8%);
  margin: 25px 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.kpdus_kpduName__I6F7n {
  margin-top: 20px;
  font-weight: bolder;
  font-size: 30px;
}

.kpdus_kpduDetails__\\+LE4E {
  font-size: 10px;
  white-space: nowrap;
  text-align: left;
  color: #000000;
  width: 90%;
}

.kpdus_selected__zsAkv .kpdus_kpduDetails__\\+LE4E {
  color: #ffffff !important;
}

.kpdus_divider__Xc7f5 {
  background-color: #e72d39;
  width: 80%;
  height: 1px;
  margin: 10px 0;
}

.kpdus_selected__zsAkv .kpdus_divider__Xc7f5 {
  background-color: #ffffff !important;
}

.kpdus_selected__zsAkv {
  background-color: #e72d39 !important;
  color: #ffffff !important;
  box-shadow: inset 0 4px 10px 1px #7e000c !important;
}

.kpdus_edit__jbpHe {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #284480;
  border-radius: 0 17px 0 10px;
  padding: 7px 12px;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/screens/kpdus/kpdus.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,yBAAyB;EACzB,6CAA6C;EAC7C,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,mDAAmD;AACrD;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,yBAAyB;EACzB,4BAA4B;EAC5B,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".container {\n}\n\n.kpduContainer {\n  cursor: pointer;\n  user-select: none;\n  min-height: 200px;\n  min-width: 174px;\n  max-height: 200px;\n  max-width: 174px;\n  border-radius: 17px;\n  color: #e72d39;\n  background-color: #ffffff;\n  box-shadow: 0 8px 13px -2px rgba(0, 0, 0, 8%);\n  margin: 25px 25px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n\n.kpduName {\n  margin-top: 20px;\n  font-weight: bolder;\n  font-size: 30px;\n}\n\n.kpduDetails {\n  font-size: 10px;\n  white-space: nowrap;\n  text-align: left;\n  color: #000000;\n  width: 90%;\n}\n\n.selected .kpduDetails {\n  color: #ffffff !important;\n}\n\n.divider {\n  background-color: #e72d39;\n  width: 80%;\n  height: 1px;\n  margin: 10px 0;\n}\n\n.selected .divider {\n  background-color: #ffffff !important;\n}\n\n.selected {\n  background-color: #e72d39 !important;\n  color: #ffffff !important;\n  box-shadow: inset 0 4px 10px 1px #7e000c !important;\n}\n\n.edit {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background-color: #284480;\n  border-radius: 0 17px 0 10px;\n  padding: 7px 12px;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kpdus_container__YfuhG`,
	"kpduContainer": `kpdus_kpduContainer__eF6mg`,
	"kpduName": `kpdus_kpduName__I6F7n`,
	"kpduDetails": `kpdus_kpduDetails__+LE4E`,
	"selected": `kpdus_selected__zsAkv`,
	"divider": `kpdus_divider__Xc7f5`,
	"edit": `kpdus_edit__jbpHe`
};
export default ___CSS_LOADER_EXPORT___;
