// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details_header_container__WE92m {
  background-color: #fafafa;
  border-radius: 0 20px 20px 0;
  margin-top: 8px;
  padding: 20px;
  margin-left: var(--sidebar-width);
  -webkit-user-select: none;
          user-select: none;
}

.details_header_kpduBreadcrumb__K-gld {
  display: flex;
  align-items: center;
  color: #192949;
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
}

.details_header_kpduBreadcrumb__K-gld div:first-of-type {
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}

.details_header_kpduBreadcrumb__K-gld div:last-of-type {
  color: #f32735;
  font-size: 20px;
  font-weight: 600;
}

.details_header_last-connection__daead {
  font-family: Arial, sans-serif;
  background-color: #f4f6f8; /* Fondo suave */
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details_header_last-connection__daead .details_header_label__Ndn44 {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.details_header_last-connection__daead .details_header_date-time__JnrP0 {
  font-weight: normal;
  color: #555;
}

`, "",{"version":3,"sources":["webpack://./src/screens/detail/components/details_header/details_header.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;EACf,aAAa;EACb,iCAAiC;EACjC,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB,EAAE,gBAAgB;EAC3C,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".container {\n  background-color: #fafafa;\n  border-radius: 0 20px 20px 0;\n  margin-top: 8px;\n  padding: 20px;\n  margin-left: var(--sidebar-width);\n  user-select: none;\n}\n\n.kpduBreadcrumb {\n  display: flex;\n  align-items: center;\n  color: #192949;\n  font-size: 20px;\n  font-weight: 600;\n  margin-right: 20px;\n}\n\n.kpduBreadcrumb div:first-of-type {\n  font-size: 24px;\n  font-weight: 700;\n  cursor: pointer;\n}\n\n.kpduBreadcrumb div:last-of-type {\n  color: #f32735;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.last-connection {\n  font-family: Arial, sans-serif;\n  background-color: #f4f6f8; /* Fondo suave */\n  padding: 10px 20px;\n  border-radius: 8px;\n  display: inline-block;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.last-connection .label {\n  font-weight: bold;\n  color: #333;\n  margin-right: 10px;\n}\n\n.last-connection .date-time {\n  font-weight: normal;\n  color: #555;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `details_header_container__WE92m`,
	"kpduBreadcrumb": `details_header_kpduBreadcrumb__K-gld`,
	"last-connection": `details_header_last-connection__daead`,
	"label": `details_header_label__Ndn44`,
	"date-time": `details_header_date-time__JnrP0`
};
export default ___CSS_LOADER_EXPORT___;
