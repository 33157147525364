import { useEffect, useState } from "react";
import { eventBus } from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import { FiEdit, FiX } from "react-icons/fi";
import axiosInstance from "../../../../AxiosInstance";
import Text from "../../../../components/ajonjolib/inputs/text/text";
import ModalFooter from "../../../../components/ajonjolib/modal/modal_footer/modal_footer";
import styles from "./index.module.css";

const EditKpduModal = ({ id, setSelectedId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const close = () => {
    setError("");
    setIsOpen(false);
    setSelectedId(null);
  };

  useEffect(() => {
    eventBus.subscribe(`edit_kpdu_${id}_open`, (rec_data) => {
      setName(rec_data.kpduName);
      setIsOpen(true);
    });
    eventBus.subscribe(`edit_kpdu_${id}_close`, () => {
      close();
    });
  }, [id]);

  const checkInput = () => {
    if (!name || name.length === 0) {
      setError("El campo no puede estar vacío.");
      return false;
    } else if (name && name.length > 15) {
      setError("Límite de caracteres excedido.");
      return false;
    }
    setError("");
    return true;
  };

  const onClick = async () => {
    if (checkInput()) {
      if (name && !loading) {
        setLoading(true);

        try {
          await axiosInstance.put(`/kpdu/${id}/update_name/`, { name: name });
          close();
          window.location.reload();
        } catch (error) {
          console.error("Error al guardar", error);
          setError("Hubo un error al guardar.");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <div>
      {isOpen && (
        <Modal close={() => close()}>
          <div className={styles["modal-container"]}>
            <div
              className={styles["modal-close-button"]}
              onClick={() => close()}
            >
              <FiX size={16} color="#6A86C8" />
            </div>
            <div className={styles["modal-header"]}>
              <div className={styles["modal-header-content"]}>
                <div className={styles["modal-header-icon"]}>
                  <FiEdit size={60} color="#284480" />
                </div>
                <div className={styles["modal-header-text"]}>
                  <div className={styles["modal-title"]}>Editar</div>
                  <div className={styles["modal-subtitle"]}>Nombre de KPDU</div>
                </div>
              </div>
            </div>
            <div className={styles["modal-body"]}>
              <div>
                <Text
                  value={name}
                  onChange={(value) => setName(value)}
                  disabled={loading}
                />
              </div>
              {error && <div className={styles["error-message"]}>{error}</div>}
            </div>
          </div>
          <ModalFooter submit={onClick} submitLoading={loading} />
        </Modal>
      )}
    </div>
  );
};

export default EditKpduModal;
