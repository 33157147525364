// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status_dot_statusDot__3jGbL {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.status_dot_greenDot__x5QF9 {
  background-color: #4caf50;
}

.status_dot_redDot__rYdUg {
  background-color: #f44336;
}
`, "",{"version":3,"sources":["webpack://./src/components/status_dot/index.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".statusDot {\n  display: inline-block;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  margin-right: 8px;\n}\n\n.greenDot {\n  background-color: #4caf50;\n}\n\n.redDot {\n  background-color: #f44336;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusDot": `status_dot_statusDot__3jGbL`,
	"greenDot": `status_dot_greenDot__x5QF9`,
	"redDot": `status_dot_redDot__rYdUg`
};
export default ___CSS_LOADER_EXPORT___;
