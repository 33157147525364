import { Spinner } from "react-bootstrap";
import styles from "./loader.module.css";

const Loader = () => {
  return (
    <div className={styles.loaderOverlay}>
      <Spinner
        animation="border"
        role="status"
        className={styles.loaderSpinner}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loader;
