import React, { useEffect } from "react";
import styles from "./kpdu_dashboard_box.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function Header({ name }) {
  return (
    <div className={styles.headerContainer}>
      <div>{name}</div>
    </div>
  );
}

function Footer() {
  return (
    <div className={`${styles.footerContainer}`}>
      <FontAwesomeIcon icon={faCaretDown} size={"2x"} color={"#CFCFCF"} />
    </div>
  );
}

export default function KpduDashboardBox({ key, data }) {
  useEffect(() => {
    console.log("Data structure:", data);
  }, [data]);

  return (
    <div className={styles.container} key={key}>
      <Header name={data.kpdu_name} />

      <div className={"d-flex justify-content-center align-items-center"}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th />
              {data.has_nicknames && <th />}
              <th>VOLTAJE</th>
              <th>CORRIENTE</th>
              <th>POTENCIA</th>
            </tr>
          </thead>
          <tbody>
            {data.outputs.map((x) => {
              return (
                <tr>
                  <td>
                    <div className={styles.output}>
                      <div>{x.id}</div>
                      <div>OUT</div>
                    </div>
                  </td>

                  {data.has_nicknames && (
                    <td>
                      <div className={styles.nickname}>{x.nickname}</div>
                    </td>
                  )}

                  <td>
                    <div className={styles.value}>{x.voltage.toFixed(1)}V</div>
                  </td>

                  <td>
                    <div className={styles.value}>{x.current.toFixed(1)}A</div>
                  </td>

                  <td>
                    <div className={styles.value}>{x.power.toFixed(1)}W</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Footer />
    </div>
  );
}
