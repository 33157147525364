import React from "react";
import { format } from "date-fns";
import styles from "./custom_tooltip.module.css";

export default function CustomTooltip({
  active,
  payload,
  frequency,
  unit,
  color,
  withSeconds = false,
  withTime = true,
}) {
  const date = payload && payload.length > 0 ? payload[0].payload.key : null;

  // const formattedDate = date
  //   ? format(new Date(date), `dd/MM - HH:mm${withSeconds ? ":ss" : ""}`)
  //   : "";

  const formattedDate = () => {
    if (withTime) {
      return format(new Date(date), `dd/MM - HH:mm${withSeconds ? ":ss" : ""}`);
    } else {
      return format(new Date(date), "dd/MM");
    }
  };

  const containerWidth = withSeconds ? "110px" : "90px";

  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: color,
        width: containerWidth,
      }}
    >
      {payload && payload.length > 0 && (
        <div className={"d-flex justify-content-center align-items-baseline"}>
          <div style={{ fontSize: "20px" }} className={"pe-1"}>
            {Math.abs(payload[0]?.value).toFixed(1)}
          </div>
          <div style={{ fontSize: "9px" }}>{unit}</div>
        </div>
      )}
      {formattedDate && (
        <div style={{ fontSize: "12px" }}>
          <strong>{formattedDate()}</strong>
        </div>
      )}
    </div>
  );
}
