// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_container__SmpB6 {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000000;
  font-family: Poppins, sans-serif;
}

.modal_backdrop__0Bn65 {
  position: fixed;
  background-color: #000000;
  opacity: 16%;
  width: 100%;
  height: 100%;
}

.modal_modal__nSuwB {
  width: 36%;
  min-width: 400px;
  background-color: #ffffff;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 24px 0 #00000040;
}

.modal_header__SBslo {
  display: flex;
  height: 69px;
  overflow-x: hidden;
  background-color: #1a2a4d;
  border-radius: 16px 16px 0 0;
}

.modal_title__GtnE\\+ {
  min-width: 250px;
  text-align: left;
  border-radius: 16px 0 0 0;
  padding: 16px 26px;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/modal/modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: 10000000;\n  font-family: Poppins, sans-serif;\n}\n\n.backdrop {\n  position: fixed;\n  background-color: #000000;\n  opacity: 16%;\n  width: 100%;\n  height: 100%;\n}\n\n.modal {\n  width: 36%;\n  min-width: 400px;\n  background-color: #ffffff;\n  border-radius: 16px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 0 4px 24px 0 #00000040;\n}\n\n.header {\n  display: flex;\n  height: 69px;\n  overflow-x: hidden;\n  background-color: #1a2a4d;\n  border-radius: 16px 16px 0 0;\n}\n\n.title {\n  min-width: 250px;\n  text-align: left;\n  border-radius: 16px 0 0 0;\n  padding: 16px 26px;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `modal_container__SmpB6`,
	"backdrop": `modal_backdrop__0Bn65`,
	"modal": `modal_modal__nSuwB`,
	"header": `modal_header__SBslo`,
	"title": `modal_title__GtnE+`
};
export default ___CSS_LOADER_EXPORT___;
