import React from "react";
import styles from "./index.module.css";

function StatusDot({ isReady }) {
  const statusClass = isReady ? styles.greenDot : styles.redDot;

  return <span className={`${styles.statusDot} ${statusClass}`}></span>;
}

export default StatusDot;
