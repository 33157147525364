import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import get from "lodash/get";
import { format } from "date-fns";

import styles from "./line_graph.module.css";
import CustomToolTip from "./custom_tooltip/custom_tooltip";

function CustomYAxis({ x, y, payload }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#000" fontSize={"12px"}>
        {parseInt(payload.value)}
      </text>
    </g>
  );
}

export default function LineGraph({
  header,
  color,
  secondaryColor,
  entries,
  valueName,
  min,
  max,
  unit,
  keyProperty,
  withSeconds = false,
  withTime = true,
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const finalEntries = entries?.map((entry, index) => {
      const value = get(entry, valueName);
      const date = get(entry, keyProperty);

      return {
        key: date,
        value: value,
      };
    });
    setData(finalEntries);
  }, [entries]);

  const getTicks = (array) => {
    const ticksCount = 5;
    const ticks = [];

    if (array.length < ticksCount) {
      return array;
    }

    const positions = [
      0,
      Math.floor(array.length * 0.25),
      Math.floor(array.length * 0.5),
      Math.floor(array.length * 0.75),
      array.length - 1,
    ];

    positions.forEach((index) => {
      ticks.push(array[index]?.key);
    });

    return ticks;
  };

  // const ticks = [data[0]?.key, data[data.length - 1]?.key];
  // const ticks = data.map((entry) => entry.key);
  const ticks = getTicks(data);

  return (
    <div className={styles.container}>
      {header && header}
      <div style={{ width: "100%", height: "200px" }}>
        <ResponsiveContainer width={"95%"} height={"100%"}>
          <AreaChart data={data} height={"100%"}>
            {/* <CartesianGrid/> */}
            <XAxis
              dataKey="key"
              padding={{ left: 10, right: 10 }}
              stroke={"#d9d9d9"}
              strokeWidth={2}
              tick={{ fill: "#999", fontSize: "14px" }}
              tickFormatter={(tick, number) => {
                if (withTime)
                  return format(new Date(tick), "dd/MM HH:mm");
                else
                  return format(new Date(tick), "dd/MM");
              }}
              ticks={ticks}
              label={{
                value: "t",
                position: "insideBottomRight",
                fill: color,
                fontSize: "12px",
                fontWeight: "600",
                marginTop: 100,
                offset: -5,
              }}
            />
            <YAxis
              dataKey="value"
              domain={[min, max]}
              padding={{ bottom: 15, top: 30 }}
              stroke={"#d9d9d9"}
              strokeWidth={2}
              tick={<CustomYAxis />}
              label={{
                value: unit,
                position: "insideTop",
                fill: color,
                fontSize: "12px",
                fontWeight: "600",
              }}
            />
            <Tooltip
              content={
                <CustomToolTip
                  color={secondaryColor}
                  chartData={data}
                  unit={unit}
                  withSeconds={withSeconds}
                  withTime={withTime}
                />
              }
            />
            <Area
              fill="url(#colorValue)"
              type="monotone"
              dataKey="value"
              stroke={color}
              strokeWidth={3}
              unit={unit}
              dot={{ r: 0, fill: "#FFF" }}
              activeDot={{ r: 0 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
