// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_tooltip_container__\\+CbJ7 {
    background-color: #A30012;
    border-radius: 13px;
    height: 58px;
    width: 90px;
    vertical-align: middle;
    color: #FFFFFF;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/screens/detail/components/line_graph/custom_tooltip/custom_tooltip.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,cAAc;IACd,gCAAgC;IAChC,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".container {\n    background-color: #A30012;\n    border-radius: 13px;\n    height: 58px;\n    width: 90px;\n    vertical-align: middle;\n    color: #FFFFFF;\n    font-family: Poppins, sans-serif;\n    font-weight: 500;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `custom_tooltip_container__+CbJ7`
};
export default ___CSS_LOADER_EXPORT___;
