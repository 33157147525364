import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import styles from "./details_header.module.css";
import { formatTimezoneDate, kpduIsReady } from "../../../../utils/utils";
import StatusDot from "../../../../components/status_dot";

export default function DetailsHeader({ kpdu_id, kpduData }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/list");
  };

  const arrow = (
    <FontAwesomeIcon
      icon={faChevronRight}
      color={"#F32735"}
      className={"px-2"}
      size={"xs"}
    />
  );

  const isReady = kpduIsReady(kpduData?.last_heartbeat);

  return (
    <div
      style={{
        position: "absolute",
      }}
    >
      <div className={styles.container}>
        <div className={styles.kpduBreadcrumb}>
          <div onClick={goBack}>KPDU's</div>
          {arrow}
          <div>{kpduData?.name}</div>
          {arrow}
          <div>Detalles</div>
        </div>
        {kpduData && kpduData.last_heartbeat && (
          <div className={styles["last-connection"]}>
            <StatusDot isReady={isReady} />
            <span className={styles.label}>Últ. conexión:</span>
            <span className={styles["date-time"]}>
              {formatTimezoneDate(kpduData.last_heartbeat)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
