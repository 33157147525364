import axios from "axios";

let baseURL = process.env.REACT_APP_BACKEND_URL;
// let baseURL = "https://kpdu-backend.stracontech.com/api/";
// let baseURL = "http://localhost:8000/api/";
let refresh = false;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error?.response?.status === 401 && !refresh) {
      refresh = true;
      try {
        const response = await axiosInstance.post("token/refresh/", {
          refresh: localStorage.getItem("refresh"),
        });

        if (response?.status === 200) {
          localStorage.setItem("access", response.data.access);
          localStorage.setItem("refresh", response.data.refresh);
          window.location.reload();
        }
      } catch (err) {
        localStorage.clear();
        window.location.href = "/login";
      } finally {
        refresh = false;
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
