import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export default function ModalFooter({
  children,
  submit,
  submitLoading,
  text,
  buttonDisabled = false,
}) {
  return (
    <div className={"d-flex justify-content-between px-4 py-3"}>
      <div>{children}</div>
      <div
        onClick={() => {
          if (submit && !submitLoading) submit();
        }}
        className={"d-flex justify-content-center align-items-center"}
        style={{
          color: buttonDisabled ? "#CCC" : "#52A035",
          backgroundColor: submitLoading ? "#CCC" : "#FFF",
          borderRadius: "6px",
          padding: "8px 16px",
          cursor: submitLoading || buttonDisabled ? "not-allowed" : "pointer",
          userSelect: "none",
          border: `${buttonDisabled ? "#CCC" : "#52A035"} 1px solid`,
        }}
      >
        {!text && (
          <FontAwesomeIcon
            icon={faSave}
            size={"xl"}
            color={buttonDisabled ? "#CCC" : "#52A035"}
            className={"me-2"}
          />
        )}
        <div>{text ? text : "Guardar"}</div>
      </div>
    </div>
  );
}
