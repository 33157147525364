// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* EditKpduModal.css */
.edit_kpdu_name_modal-container__uG1ce {
  position: relative;
  padding: 30px 0;
}

.edit_kpdu_name_modal-close-button__YNsET {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.edit_kpdu_name_modal-header__A6LtR {
  text-align: left;
}

.edit_kpdu_name_modal-header-content__YJUmb {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 5px;
}

.edit_kpdu_name_modal-header-icon__9Q18y {
  margin-left: -15px;
}

.edit_kpdu_name_modal-header-text__vrRvw {
  display: flex;
  flex-direction: column;
}

.edit_kpdu_name_modal-title__3wfBR {
  color: #000;
  font-weight: 600;
  font-size: 26px;
}

.edit_kpdu_name_modal-subtitle__QeXrz {
  color: #284480;
  font-size: 16px;
}

.edit_kpdu_name_modal-body__n5ktr {
  padding-top: 15px;
  width: 500px;
  margin: 0 auto;
}

.edit_kpdu_name_error-message__BxUk- {
  color: red;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/screens/detail/modals/edit_kpdu_name/index.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["/* EditKpduModal.css */\n.modal-container {\n  position: relative;\n  padding: 30px 0;\n}\n\n.modal-close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n}\n\n.modal-header {\n  text-align: left;\n}\n\n.modal-header-content {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  gap: 5px;\n}\n\n.modal-header-icon {\n  margin-left: -15px;\n}\n\n.modal-header-text {\n  display: flex;\n  flex-direction: column;\n}\n\n.modal-title {\n  color: #000;\n  font-weight: 600;\n  font-size: 26px;\n}\n\n.modal-subtitle {\n  color: #284480;\n  font-size: 16px;\n}\n\n.modal-body {\n  padding-top: 15px;\n  width: 500px;\n  margin: 0 auto;\n}\n\n.error-message {\n  color: red;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-container": `edit_kpdu_name_modal-container__uG1ce`,
	"modal-close-button": `edit_kpdu_name_modal-close-button__YNsET`,
	"modal-header": `edit_kpdu_name_modal-header__A6LtR`,
	"modal-header-content": `edit_kpdu_name_modal-header-content__YJUmb`,
	"modal-header-icon": `edit_kpdu_name_modal-header-icon__9Q18y`,
	"modal-header-text": `edit_kpdu_name_modal-header-text__vrRvw`,
	"modal-title": `edit_kpdu_name_modal-title__3wfBR`,
	"modal-subtitle": `edit_kpdu_name_modal-subtitle__QeXrz`,
	"modal-body": `edit_kpdu_name_modal-body__n5ktr`,
	"error-message": `edit_kpdu_name_error-message__BxUk-`
};
export default ___CSS_LOADER_EXPORT___;
