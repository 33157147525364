import React, { useEffect, useState } from "react";
import styles from './kpdus.module.css';
import Sidebar from "../../components/sidebar/sidebar";
import { useNavigate } from "react-router-dom";
import ViewSelector from "./components/view_selector/view_selector";
import Table from "../../components/ajonjolib/table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEye } from "@fortawesome/free-solid-svg-icons";
import StatusPill from "../../components/status_pill/status_pill";
import axiosInstance from "../../AxiosInstance";
import { openModal } from "../../components/ajonjolib/toasts/toast/toast";
import OutputStatesModal from "../detail/modals/output_states";
import { formatTimestamp } from "../../utils/utils";

function KpduBox({ selected, data }) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const details = () => {
    navigate(`/detail/${data.id}/`);
  }

  useEffect(() => {
    const updated_at = data?.updated_at;
    // if 3 minutes since last update then inactive
    let active = false;
    let now = new Date();
    let lastActivity = new Date(updated_at);
    let diff = (now - lastActivity) / 1000;
    if (diff < 60) {
      active = true;
    }
    setIsActive(active)
  }, [data]);

  return (
    <div className={`${styles.kpduContainer} ${selected ? styles.selected : ''}`} onClick={() => details()}>
      <div className={styles.kpduName}>{data?.name}</div>
      <div className={styles.divider} />
      <div className={styles.kpduDetails}>
        <div className={'d-flex justify-content-between'}>
          <div style={{ fontWeight: 'bold' }}>U. Con</div>
          <div style={{ fontWeight: 'lighter' }}>{formatTimestamp(data.updated_at)}</div>
        </div>
        <div className={'d-flex justify-content-between'}>
          <div style={{ fontWeight: 'bold' }}>Estado</div>
          <div style={{ fontWeight: 'lighter' }}>{isActive ? 'Activo' : 'Inactivo'}</div>
        </div>
      </div>
    </div>
  )
}

function KpduItemView({ data }) {
  return (
    <div className="d-flex flex-wrap">
      {data?.map((entry) => {
        return <KpduBox key={entry.id} data={entry} />
      })}
    </div>
  )
}

function KpduListView({ data }) {
  const navigate = useNavigate();
  const cols = [
    { title: 'Nombre', key: 'name', code: (row, data) => { return <div style={{ color: '#F32735', fontWeight: 600 }}>{data}</div> } },
    { title: 'Modelo de KPDU', key: 'model.name' },
    { title: 'Serial de Vehículo', key: 'reference' },
    { title: 'Serial de KPDU', key: 'ipv4' },
    {
      title: 'Última Conexión', key: 'updated_at', code: (row, data) => {
        return formatTimestamp(data);
      }
    },
    {
      title: 'Estado', key: 'updated_at', code: (row, data) => {
        // if 3 minutes since last update then inactive
        let active = false;
        let now = new Date();
        let lastActivity = new Date(data);
        let diff = (now - lastActivity) / 1000;
        if (diff < 60) {
          active = true;
        }
        return <StatusPill value={active} />
      }
    },
  ]

  return (
    <Table
      model={'kpdu'}
      cols={cols}
    />
  )
}

export default function Kpdus() {
  const [viewType, setViewType] = useState('items')
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance.get('kpdu/').then((response) => {
      if (response?.status !== 200) {
        return;
      }
      setData(response.data);
    });
  }, []);

  return (
    <div className={styles.container}>
      <Sidebar selectedIndex={1} />
      <ViewSelector setViewType={setViewType} viewType={viewType} />
      <OutputStatesModal />

      <div className={'d-flex'} style={{
        marginLeft: '300px',
        marginRight: '20px',
        paddingTop: '80px'
      }}>
        {viewType === 'items' ? <KpduItemView data={data} /> : <KpduListView data={data} />}
      </div>
    </div>
  );
}
